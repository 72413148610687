.container{
    padding-top: 130px;
    min-height: 100dvh;
    width: var(--main-width);
    margin: 0 auto;

    .header{
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        h1{
            font-size: 1.6rem;
            font-weight: 500;
            color: var(--main-color);
        }
    }

    .listWrapper{
        width: 100%;
        display: flex;
        background-color: var(--background-color-2);
        border: 0.5px solid var(--border-color-2);
        border-radius: 4px;
        padding: 5px 20px;

        ul{
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            width: 100%;
            li{
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 0.95rem;
                color: var(--white-text-color-2);
                padding: 10px 0;
                border-bottom: 0.5px solid var(--border-color-3);

                &:last-child{
                    border-bottom: none;
                }

                .status{
                    display: flex;
                    gap: 10px;
                    align-items: center;

                    .toggleUser{
                        cursor: pointer;
                        padding: 7px 8px 5px;
                        border-radius: 50%;
                        background-color: var(--primary-color-1);
                        border: 0.5px solid var(--border-color-2);
                        margin: auto;
                        transition: all .12s ease-in-out;

                        svg{
                            width: 0.95rem;
                            height: 0.95rem;
                            margin: 0;
                            padding: 0;
                        }

                        &:hover{
                            background-color: var(--background-color-3);
                        }
                    }
                }
            }
        }
    }
}