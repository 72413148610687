.container{
    width: var(--main-width);
    margin: 0 auto;
    padding-bottom: 50px;

    &::before{
        content: '';
        height: 460px;
        max-width: 100vw;
        width: 100vw;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(120deg, rgb(26, 32, 53) 0%, rgb(37, 39, 46) 50%, rgb(40, 35, 50) 100%);
        z-index: -2;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 460px;
        max-width: 100vw;
        width: 100vw;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        background: linear-gradient(to bottom, transparent, var(--background-color-1));
        z-index: -1;
      }


    .hero{
        padding: calc(50dvh - 250px) 0 0 0;

        .header{
            text-align: center;
            margin-bottom: 20px;




            h1{
                font-size: 2.2rem;
                background: linear-gradient(to bottom, var(--white-text-color-1) 0%, var(--white-text-color-3) 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 700;
            }

            p{
                font-size: 0.95rem;
                font-weight: 200;
                color: var(--white-text-color-2);
                margin-top: 10px;
            }

            .buttons{
                display: flex;
                justify-content: center;
                margin-top: 20px;
                gap: 17px;

                a{
                    position: relative; // added
                    border-radius: 20px;
                    padding: 9px 17px 8px 17px;
                    font-size: 1rem;
                    font-weight: 300;
                    text-decoration: none;

                    &:first-child{
                        background: linear-gradient(140deg, var(--secondary-color-1) 0%, var(--secondary-color-2) 100%);
                        color: var(--white-text-color-1);
                    }
                    &:last-child {
                        background: transparent;
                        color: var(--white-text-color-1);
                        border: none;
                        
                    }
                }
            }
        }

        .content{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 230px;
            
            .details{
                margin: auto 0;
                width: 60%;
                h1{
                    font-size: 1.5rem;
                    font-weight: 700;
                    color: var(--white-text-color-1);
                    margin-bottom: 10px;
                }
                p{
                    font-size: 0.95rem;
                    font-weight: 200;
                    color: var(--white-text-color-2);
                    margin-bottom: 10px;
                }

                a{
                    font-size: 0.95rem;
                    font-weight: 400;
                    color: var(--black-text-color-2);
                    background-color: var(--primary-color-1);
                    width: fit-content;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                    padding: 6px 10px;
                    border-radius: 3px;
                }
            }

            .preview{
                width: 40%;
                .card{
                    background-color: var(--background-color-2);
                    border-radius: 7px;
                    padding: 6px 9px;
                    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.07);

                    .list{
                        display: flex;
                        flex-direction: column;
                        user-select: none;

                        .userAction{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            border-bottom: 0.5px solid var(--border-color-3);
                            padding: 10px;

                            h1 {
                                display: flex;
                                align-items: center; // centers items vertically
                                justify-content: center; // centers items horizontally
                                font-size: 0.9rem;
                                font-weight: 500;
                                color: var(--white-text-color-1);
                              
                                svg {
                                  height: 1.2rem;
                                  width: 1.2rem;
                                  background-color: var(--accent-color-1);
                                  border-radius: 50%;
                                  padding: 4px;
                                  margin-right: 10px;
                                }
                            }

                            p{
                                font-size: 0.75rem;
                                font-weight: 200;
                                color: var(--white-text-color-2);
                            }

                            .actions{
                                display: flex;
                                gap: 10px;

                                svg{
                                    height: 1.2rem;
                                    width: 1.2rem;
                                    background-color: var(--background-color-3);
                                    border-radius: 3px;
                                    padding: 4px;
                                    vertical-align: middle;
                                    cursor: pointer;
                                    transition: all .12s ease-in-out;

                                    &:first-child:hover{
                                        background-color: var(--error-bg);
                                    }

                                    &:last-child:hover{
                                        background-color: var(--secondary-color-1);
                                    }
                                }
                            }

                            &:nth-child(1){
                                h1 svg{
                                    background-color: var(--error-bg);
                                }
                            }

                            &:nth-child(2){
                                h1 svg{
                                    background-color: var(--info-bg);
                                }
                                opacity: 0.7;
                            }

                            &:nth-child(3){
                                h1 svg{
                                    background-color: var(--info-bg);
                                }
                                opacity: 0.5;
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
    }


    .mockup{
        display: flex;
        flex-direction: row;
        margin-top: 300px;
        gap: 220px;
        padding: 20px 18px;

        .block{
            width: fit-content;

            h1{
                font-size: 1.5rem;
                font-weight: 700;
                color: var(--white-text-color-1);
                margin-bottom: 20px;
                margin-top: 40px;
            }

            p{
                font-size: 0.95rem;
                font-weight: 200;
                color: var(--white-text-color-2);
                margin-bottom: 20px;
            }
            
            img{
                display: block;
                height: 450px;
                border-radius: 5px;
            }
        }
    }

    .features{
        width: 100%;
        display: flex;
        margin-top: 300px;
        justify-content: space-between;

        .card{
            max-width: 300px;
            h1{
                font-size: 2rem;
                font-weight: 300;
                color: var(--white-text-color-1);
                margin-bottom: 20px;
            }

            p{
                font-size: 0.95rem;
                font-weight: 200;
                color: var(--white-text-color-2);
                margin-bottom: 15px;
            }

            a{
                height: 35px;
                width: 35px;
                background-color: var(--primary-color-1);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all .12s ease-in-out;
                
                svg{
                    height: 20px;
                    width: 20px;
                    color: var(--black-text-color-1);
                    transition: all .12s ease-in-out;
                }

                &:hover{
                    background-color: var(--secondary-color-2);

                    svg{
                        color: var(--white-text-color-3);
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }



    .push{
        .content{
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 230px;
            
            .details{
                margin: auto 0;
                width: 50%;
                padding-left: 30px;
                h1{
                    font-size: 1.5rem;
                    font-weight: 700;
                    color: var(--white-text-color-1);
                    margin-bottom: 10px;
                }
                p{
                    font-size: 0.95rem;
                    font-weight: 200;
                    color: var(--white-text-color-2);
                    margin-bottom: 10px;
                }

                a{
                    font-size: 0.95rem;
                    font-weight: 400;
                    color: var(--black-text-color-2);
                    background-color: var(--primary-color-1);
                    width: fit-content;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                    padding: 6px 10px;
                    border-radius: 3px;
                }
            }

            .preview{
                width: fit-content;
                background-color: var(--background-color-2);
                border-radius: 10px;
                padding: 18px;
                box-shadow: 0 4px 5px rgba(0, 0, 0, 0.07);


                .card{
                    height: fit-content;
                    min-width: 600px;
                    background: linear-gradient(140deg, var(--accent-color-1) 0%, var(--secondary-color-1) 45%, var(--secondary-color-1) 58%,  var(--accent-color-1) 100%);
                    border-radius: 4px;

                    .notification{
                        height: 100%;
                        width: 100%;
                        border-radius: 4px;
                        backdrop-filter: blur(20px);
                        
                        .header{
                            padding: 15px 30px 10px 15px;

                            border-top-left-radius: 4px;
                            border-top-right-radius: 4px;
                            background-color: rgba(255, 255, 255, 0.05);
                            backdrop-filter: blur(20px);

                            .app{
                                display: flex;
                                align-items: center;

                                svg{
                                    background-color: var(--white-text-color-1);
                                    color: var(--black-text-color-1);
                                    height: 1.5rem;
                                    width: 1.5rem;
                                    border-radius: 4px;
                                    padding: 2px;
                                }
                                h1{
                                    text-transform: uppercase;
                                    font-size: 0.8rem;
                                    color: rgba(0, 0, 0, 0.3);
                                    font-weight: 700;
                                    margin-left: 10px;
                                }
    
                                p{
                                    font-size: 0.8rem;
                                    margin-left: auto;
                                    font-weight: 700;
                                    color: rgba(0, 0, 0, 0.3);
                                }
                            }

                            h2{
                                font-size: 0.95rem;
                                font-weight: 500;
                                margin-left: 4px;
                                color: rgba(0, 0, 0, 0.4);
                                margin: 10px 0 0;
                            }
                        }

                        .actions{
                            display: block;
                            padding: 10px 15px;

                            p{
                                font-size: 0.75rem;
                                font-weight: 500;
                                margin-left: 4px;
                                color: rgba(0, 0, 0, 0.4);
                                margin: 10px 0 0 5px;
                            }

                            .buttons{
                                display: flex;
                                gap: 15px;
                                margin-top: 15px;
                                width: 100%;

                                a{
                                    cursor: pointer;
                                    backdrop-filter: blur(20px);
                                    font-size: 0.9rem;
                                    font-weight: 500;
                                    color: var(--black-text-color-1);
                                    background-color: rgba(255, 255, 255, 0.08);
                                    width: fit-content;
                                    text-decoration: none;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    padding: 13px 0;
                                    border-radius: 3px;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    .benefits{
        width: 100%;
        margin-top: 280px;
        display: flex;
        gap: 10px;

        .block{
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .header{
                min-height: 180px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                h1{
                    font-size: 2.2rem;
                    font-weight: 400;
                    color: var(--white-text-color-1);
                    margin: 0px;
                    display: flex;
    
                    &::before{
                        content: '';
                        display: inline-block;
                        height: 8px;
                        width: 8px;
                        background-color: var(--accent-color-1);
                        border-radius: 50%;
                        margin: auto 15px auto 0;
    
                    }
                }
    
                h2{
                    font-size: 2.2rem;
                    font-weight: 400;
                    background: linear-gradient(to bottom, var(--white-text-color-1) 40%, var(--white-text-color-3) 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin: 0px;
                    display: flex;
                }
            }

            .card{
                height: 180px;
                border-radius: 4px;
                background: linear-gradient(-45deg, transparent 45%, var(--background-color-2) 100%);
                padding: 10px 25px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 10px;

                h1{
                    font-size: 1.5rem;
                    font-weight: 300;
                    color: var(--white-text-color-2);
                    margin: 0px;
                }

                p{
                    font-size: 0.85rem;
                    font-weight: 200;
                    color: var(--white-text-color-3);
                    width: 70%;
                }
            }
        }
    }
}




@media (max-width: 1400px){
    .container .hero { 
        .header{
            h1{
                font-size: 1.8rem;
            }
            p{
                font-size: 0.8rem;
            }

            .buttons{
                width: 100%;
                margin: 35px auto 0;
                a{
                    width: 140px;
                    padding: 7px 0 6px 0;
                    font-size: 0.85rem;
                }
            }
        }

        .content {
            flex-direction: column;

            .details{
                width: 100%;
            }

            .preview {
                margin: 10px 0 0 auto;
                width: 490px;
            }
        }
    }

    .container .push .content {
        display: flex;
        flex-direction: column;
        .details{
            width: 100%;
            padding-left: 0;

            margin-bottom: 40px;
        }

        .preview{
            width: 100%;
            .card{
                min-width: 100%;
            }
        }
    }

    .container .benefits {
        flex-direction: column;

        .block{
            width: 100%;
            .header{
                h1{
                    width: fit-content;
                    margin: 0 auto;
                    font-size: 1.7rem;

                    &::before{
                        height: 5px;
                        width: 5px;
                    }
                }
                h2{
                    width: fit-content;
                    margin: 0 auto;
                    font-size: 1.7rem;
                }
            }
        }
    }

    .container .mockup{
        display: none;
    }
}

@media (max-width: 640px){

    .container .hero{
        padding-top: 200px;
    }
    .container .hero .content .preview {
        width: 100%;
        margin: 30px 0 0 0;
    }

    .container .features {
        flex-direction: column;
        gap: 40px;

        .card{
            width: 100%;
            max-width: 100%;
            margin: 0 auto;

            h1{
                font-size: 1.8rem;
                margin-bottom: 10px;
            }

            p{
                font-size: 0.9rem;
                margin-bottom: 20px;
            }

        }
    }


    .container .push .content .preview{
            padding: 10px;
            .card .notification{
                .header {
                    .app{
                        h1{
                            font-size: 0.7rem;
                        }
                        p{
                            font-size: 0.7rem;
                        }
                    }

                    h2{
                        font-size: 0.8rem;
                    }
                }

                .actions .buttons a{
                    font-size: 0.8rem;
                    padding: 10px 0;
                }
            }
    }
}