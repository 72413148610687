*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


body{
    background-color: var(--background-color-1);
    color: var(--white-text-color-1);
    font-family: 'Inter', sans-serif;

}


:root{
    --main-width: 83dvw;
    --nav-width: 250px;

    --background-color-1: #1B1B1F;
    --background-color-2: #242429;
    --background-color-3: #2E2E33;

    --white-text-color-1: #f6f6f9;
    --white-text-color-2: #c3c3c3;
    --white-text-color-3: #75808A;

    --black-text-color-1: #1d1d22;
    --black-text-color-2: #0e0e11;
    --black-text-color-3: #000000;

    --border-color-1: #1d1d22;
    --border-color-2: #25252a;
    --border-color-3: #2f2f34;

    --primary-color-1: #40457F;
    --secondary-color-1: #393d71;
    --secondary-color-2: #443c7a;
    --accent-color-1: #92839C;


    /* --- ACTION COLORS ---  */
    --error-text: #FF3D3D;
    --error-bg: #ff433154;
    --success-text: #00D68F;
    --success-bg: #00D68F1A;
    --warning-text: #FFAA00;
    --warning-bg: #FFAA001A;
    --info-text: #0095FF;
    --info-bg: #0095ff54;
}

@media (max-width: 1400px){
    :root{
        --main-width: 87vw;
    }
}

@media (max-width: 640px){
    :root{
        --main-width: 92vw;
    }
}