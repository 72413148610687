.container{
    max-height: 100dvh;
    height: 100dvh;
    margin: 0 auto;
    display: flex;
    position: relative;
    background-color: var(--background-color-2);
   

    .block{
        height: 100%;
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:first-child{
            width: 55%;
        }

        &:last-child {
            position: relative;
            max-height: 100%;
            max-width: 45%;
            background-size: 50px 50px;
            background-image: 
                linear-gradient(to right, var(--border-color-3) 0.5px, transparent 1px),
                linear-gradient(to bottom, var(--border-color-3) 0.5px, transparent 1px);
            background-color: var(--background-color-1);
        }

        .card{
            width: 100%;
            height: 100%;
            max-height: 100%;
            
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: var(--background-color-2);


            form{
                width: 630px;        
                display: flex;
                flex-direction: column;

                h1{
                    font-size: 2.2rem;
                    font-weight: 600;
                    color: var(--main-color);
                    margin-bottom: 30px;
                }


                label{
                    font-size: 0.75rem;
                    text-transform: uppercase;
                    font-weight: 400;

                    color: var(--accent-color-1);
                    margin: 25px 0 3px 5px;
                }

                input{
                    width: 100%;
                    padding: 10px 10px;
                    border: none;
                    border-bottom: 0.5px solid var(--border-color-3);
                    background-color: transparent;
                    color: var(--white-text-color-1);

                    &:focus{
                        outline: none;
                        border-bottom: 0.5px solid var(--secondary-color-1);
                    }

                    &:nth-child(8){
                        margin-top: 20px;
                    }
                }

                p{
                    font-size: 0.65rem;
                    font-weight: 200;
                    color: var(--error-text);
                    margin-top: 10px;
                }

                .doubleInput{
                    display: flex;
                    gap: 15px;

                    .streetNumber{
                        width: 30%;
                    }

                    &:last-child{
                        margin-top: 50px;
                    }
                }

                button{
                    width: fit-content;
                    padding: 10px 20px;
                    background-color: var(--primary-color-1);
                    color: var(--white-text-color-1);
                    border: none;
                    border-radius: 20px;
                    cursor: pointer;
                    transition: background-color 0.12s;

                    &:hover{
                        background-color: var(--accent-color-1);
                    }
                }

                a{
                    font-size: 0.85rem;
                    font-weight: 200;
                    color: var(--white-text-color-2);
                    display: flex;
                    align-self: center;
                    text-align: center;
                    text-decoration-color: var(--white-text-color-3);
                    margin-left: auto;
                }

            }
        }
    }
}

@media (max-width: 1200px){
    .container{
        background-image: none;
        background-color: var(--background-color-2);
        align-items: flex-start;

        .block {
            padding-top: 100px;

            &:first-child{
                width: 100%;
            }

            &:last-child{
                display: none;
            }

            .card{
                padding: 50px 25px;
                form{
                    h1{
                        margin-bottom: 40px;
                        font-size: 1.8rem;
                        text-align: center;
                    }

                    label{
                        font-size: 0.6rem;
                    }

                    input{
                        font-size: 16px;
                        background-color: var(--background-color-3);
                        border-bottom: 0;
                        border-radius: 4px;
                    }

                    button{
                        padding: 13px 40px;
                    }
                }
            }
        }
    }
}


@media (max-width: 640px){
    .container{
        background-image: none;
        background-color: var(--background-color-2);
        align-items: flex-start;

        .block {
            padding-top: 100px;

            &:first-child{
                width: 100%;
            }

            &:last-child{
                display: none;
            }

            .card{
                padding: 50px 25px;
                form{
                    width: 100%;
                    padding-bottom: 30px;
                    h1{
                        margin-bottom: 20px;
                        font-size: 1.8rem;
                        text-align: center;
                    }

                    label{
                        font-size: 0.6rem;
                    }

                    input{
                        font-size: 16px;
                        background-color: var(--background-color-3);
                        border-bottom: 0;
                        border-radius: 4px;
                    }

                    button{
                        padding: 13px 40px;
                    }

                    .doubleInput:nth-child(3){
                        flex-direction: column;
                    }
                }
            }
        }
    }
}