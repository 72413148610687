.container{
    min-height: 100vh;
    width: var(--main-width);
    margin: 0 auto;
    padding-top: 130px;

    .header{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        h1{
            font-size: 1.6rem;
            font-weight: 500;
            color: var(--main-color);
            margin: 0 0 7px 0;
        }

        p{
            font-size: 0.95rem;
            font-weight: 300;
            color: var(--white-text-color-2);
        }

        .selector{
            display: flex;
            width: fit-content;
            margin-top: 30px;
            background-color: var(--background-color-2);
            border: 0.5px solid var(--border-color-2);
            outline: none;
            padding: 8px 15px;
            color: var(--white-text-color-1);
            font-size: 0.95rem;
        }
    }

    .content{
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: var(--background-color-2);
        border: 0.5px solid var(--border-color-2);
        padding: 10px 20px;

        .message{
            padding: 10px 0;
            border-bottom: 0.5px solid var(--border-color-3);
            font-size: 0.95rem;
            color: var(--white-text-color-2);

            &:last-child{
                border-bottom: none;
            }
        }
    }
}